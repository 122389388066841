
angular
   .module('vdsr')
   .service('countdown', ['$timeout', countdownService])

/**
 * 
 * @typedef {{remaining: number, cancel:() => boolean}} CountdownInstance 
 */

/**
 * @constructor
 * @param {angular.ITimeoutService} $timeout
 */
function countdownService($timeout) {
   this.create = Countdown;

   /**
    * 
    * @param {number} duration - in seconds
    * @param {() => void} [callback] - fn to call when done 
    */
   function Countdown(duration, callback) {
      let remaining = duration;
      let done = false;
      /** @type {ReturnType<$timeout>} */
      var timeout ;

      let run = () => timeout = $timeout(update, 1000);
      function update () {
         if (remaining <= 0) {
            done = true;
            callback && callback()
         } else {
            remaining--;
            run();
         }
      }
      update();
      /** @type {CountdownInstance} */
      return {
         get done() { return done; },
         get remaining () {return remaining},
         cancel: () => $timeout.cancel(timeout)
      }
   }
}
